import { MenuItemLink, MenuProps, ReduxState, useTranslate} from "react-admin";
import {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../types";
import SubMenu from "../SubMenu";
import StoreIcon from '@material-ui/icons/Store';
import members from "../../resources/members";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";

type MenuName = 'index';

export default ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        index: true,
    });
    const translate = useTranslate();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    // @ts-ignore
    return (
        <SubMenu
            handleToggle={() => handleToggle('index')}
            isOpen={state.index}
            name="common.component.menu.brand"
            icon={<StoreIcon />}
            dense={dense}
        >
            <MenuItemLink
                to={{
                    pathname: '/members',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.brand.members`, {
                    smart_count: 2,
                })}
                leftIcon={<members.icon />}
                dense={dense}
            />
        </SubMenu>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));