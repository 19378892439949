import GroupIcon from '@material-ui/icons/Group';

import ResourceList from '../../component/members/ResourceList';
import ResourceCreate from '../../component/members/ResourceCreate';
import ResourceEdit from '../../component/members/ResourceEdit';

const resource = {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    icon: GroupIcon,
};

export default resource;
