import * as React from 'react';
import { Box, useMediaQuery, Theme } from '@material-ui/core';
import {
    ListBase,
    ListProps,
    Pagination,
    Title,
    useListContext,
    useTranslate, useResourceContext, TopToolbar, CreateButton,
} from 'react-admin';

import ImageList from './ImageList';
import Aside from './Aside';


const ResourceList = (props: ListProps) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <ListBase
            perPage={20}
            sort={{ field: 'id', order: 'desc' }}
            {...props}
        >
            <ProductListView isSmall={isSmall} />
        </ListBase>
    );
};
const ResourceListTitle = () => {
    const translate = useTranslate();
    return <span>{translate(`common.component.submenu.delivery_takeout.products`)}</span>;
}
const ResourceListActions = ( ) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);
const ProductListView = ({ isSmall }: { isSmall: boolean }) => {
    const { defaultTitle } = useListContext();
    return (
        <>
            <ResourceName />
            <Title title={<ResourceListTitle />} />
            <Box display="flex">
                <Aside />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <ResourceListActions/>
                    <ImageList />
                    <Pagination rowsPerPageOptions={[10, 20, 40]} />
                </Box>
            </Box>
        </>
    );
};
const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
export default ResourceList;
