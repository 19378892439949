import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    common: {
        component: {
            dashboard: {
              title: 'バリュート百貨店管理画面',
            },
            menu: {
                dashboard: 'ダッシュボード',
                delivery: 'デリバリー',
                takeout: 'テイクアウト',
                delivery_takeout: '商品関連',
                onlineshop: 'お取り寄せ',
                brand: '店舗情報',
            },
            submenu: {
                delivery: {
                    schedules: '本日対応',
                    orders: '受注',
                    sale_types: '支払い方法管理',
                },
                delivery_takeout: {// デリバリー・テイクアウト共有箇所
                    products: '商品',
                    toppings: 'トッピング',
                    topping_groups: 'トッピンググループ',
                },
                brand: {
                    members: 'ユーザー',
                },
            }
        },
    },
    view: {
        dashboard: {
            revenue_summary: {
                title: "本日売上",
                monthly: "当月売上",
                daily: "本日売上",
                delivery: "本日デリバリー売上",
                takeout: "本日テイクアウト売上",
            },
            new_orders: {
                title: "新規受付",
                delivery: "デリバリー",
                takeout: "テイクアウト",
            },
            order_chart: {
                title: "月間売上遷移",
            },
        },
        common: {
            title: {
                delivery: "【デリバリー】",
                takeout: "【テイクアウト】",
            },
            profile_setting: "プロフィール設定",
        },
        products: {
            category: 'カテゴリ',
            brand: 'ブランド',
            sale_type: '注文可能タイプ',
            status: '公開状況',
            tabs: {
                edit_product: '商品情報',
                edit_sale: '販売情報',
            },
            upload_image: '商品画像(複数可)をアップロードしてください。(1枚あたり最大500KB)'
        },
        orders: {
            tabs: {
                edit_order: '受注情報',
                edit_customer: 'お客様情報',
                edit_product: '購入商品情報',
            },
            doc:{
                send_to: '送付先',
                name_surfix: '宛名敬称',
                receipt:{
                    create: '領収書メール送付',
                    name: '宛名',
                    message: '但し書き',
                },
                invoice:{
                    create: '請求書メール送付',
                    name: '請求書宛名',
                    message: 'メッセージ',
                }
            }
        },
        schedules: {
            tabs: {
                show_ordered: '新規受付',
                show_in_progress: '対応中',
                show_finished: '対応完了',
                show_canceled: 'キャンセル',
            },
        }
    },
    models:{
        common: {
            create_date: "作成日時",
            update_date: "更新日時",
        },
        topping_groups: {
            id: 'トッピンググループID',
            name: 'トッピンググループ名',
        },
        toppings: {
            id: 'トッピングID',
            name: 'トッピング名',
            price: '販売価格',
            tax_rate: '税率',
            price_in_tax: '税込価格',
            stock: '在庫数',
            stock_unlimited: '在庫無制限',
            topping_group: 'トッピンググループ',
            display_order: '表示順',
            memo: 'メモ',
            status: '公開状況',
        },
        products: {
            id: '商品ID',
            name: '商品名',
            image: '商品画像',
            price: '販売価格',
            tax_rate: '税率',
            price_in_tax: '税込価格',
            stock: '在庫数',
            stock_unlimited: '在庫無制限',
            category: 'カテゴリ',
            topping_group: 'トッピンググループ',
            catalog_product_detail: '商品詳細説明',
            status: '公開状況',
            sale_type: '販売タイプ',
            display_order: '表示順',
            precautionary_statement: '注意文言',
            sale_limit_by_delivery_date: '最短選択可能日付',
            minimum_purchase_amount: '最小購入数',
            brand: 'ブランド',
        },
        orders: {
            id: '受注ID',
            order_status: '注文ステータス',
            product_info: '購入商品情報',
            customer_name: 'お名前',
            customer_name_kana: 'お名前（カナ）',
            customer_email: 'メールアドレス',
            customer_tel: '電話番号',
            delivery_info: '配送情報',
            delivery_postcode: '配送先郵便番号',
            delivery_address: '配送先住所',
            delivery_building: '配送先建物名',
            customer_info: 'お客様情報',
            customer_postcode: 'お客様郵便番号',
            customer_address: 'お客様住所',
            customer_building: 'お客様住所建物名',
            order_datetime: '配送日時',
            order_date: {
                delivery: '配達日',
                takeout: '受取日',
            },
            order_time: {
                delivery: '配達時間',
                takeout: '受取時間',
            },
            sale_type: '注文種別',
            receipt: '領収書有無',
            message: '備考',
            order_item: '購入商品',
            total: '購入金額',
            create_date: '購入日時',
            payment_method: '支払方法',
            memo: 'メモ',
            brand: 'ブランド',// デリテイクは現状複数ブランドにまたがって購入できないので、表示用にブランドを取得している
        },
        order_items: {
            is: '受注詳細ID',
            product_name: '商品名',
            product_image: '商品画像',
            product_price: '販売価格',
            tax_rate: '税率',
            order_count: '購入個数',
            order_toppings: 'トッピング',
        },
        order_toppings: {
            topping_name: 'トッピング',
            topping_count: '個数',
        },
        members: {
            name: '名前',
            department: '所属',
            login_id: 'ログインID',
            authority: '権限',
            work: '稼働',
            brand: 'ブランド'
        }
    },
    ra: {// ダッシュボード用
        action: {
            add_filter: 'フイルターを追加する',
            add: '追加する',
            back: '戻る',
            bulk_actions: '1 レコードが選択されました |||| %{smart_count} レコードが選択されました',
            cancel: 'キャンセルする',
            clear_input_value: '空にする',
            clone: '複製する',
            confirm: '確認する',
            create: '追加する',
            create_item: '%{item}を追加する',
            delete: '削除する',
            edit: '編集する',
            export: 'CSV出力する',
            list: '一覧',
            refresh: 'リフレッシュする',
            remove_filter: 'フィルターを削除する',
            remove: '削除する',
            save: '保存する',
            search: '検索する',
            select_all: 'すべて選択する',
            select_row: 'このレコードを選択する',
            show: '参照する',
            sort: 'ソートする',
            undo: 'もとに戻す',
            unselect: '選択をやめる',
            expand: '拡張する',
            close: '閉じる',
            open_menu: 'メニューを開く',
            close_menu: 'メニューを閉じる',
            update: '更新する',
            move_up: '上に移動する',
            move_down: '下に移動する',
            send:'メール送信',
            sent:'送信しました',
            pdf_create:'メール送信',
        },
        boolean: {
            true: 'はい',
            false: 'いいえ',
            null: '選択しない',
        },
        page: {
            create: '%{name}を追加する',
            dashboard: 'ダッシュボード',
            edit: '%{name} #%{id}',
            error: 'システムエラー',
            list: '%{name}',
            loading: '読込中です...',
            not_found: '見つかりませんでした',
            show: '%{name} #%{id}',
            empty: '該当レコードは存在しません。',
            invite: 'レコードを作成しますか？',
        },
        input: {
            file: {
                upload_several:
                    'ファイルをドラッグ・アンド・ドロップするか選択してください。(複数可)',
                upload_single: 'ファイルをドラッグ・アンド・ドロップするか選択してください。',
            },
            image: {
                upload_several:
                    '画像ファイルをドラッグ・アンド・ドロップするか選択してください。(複数可)',
                upload_single:
                    '画像ファイルをドラッグ・アンド・ドロップするか選択してください。',
            },
            references: {
                all_missing: '全ての参照先が見つかりませんでした。',
                many_missing:
                    '一つ以上の参照先が見つかりませんでした。',
                single_missing:
                    '参照先が見つかりませんでした。',
            },
            password: {
                toggle_visible: 'パスワードを隠す',
                toggle_hidden: 'パスワードを表示する',
            },
        },
        message: {
            about: '',
            are_you_sure: '本当によろしいでしょうか',
            bulk_delete_content:
                '%{name} を削除しますか。 |||| %{smart_count} を削除しますか。',
            bulk_delete_title:
                '%{name} を削除します |||| %{smart_count} %{name} を削除します',
            bulk_update_content:
                '%{name} を更新しますか。 |||| %{smart_count} を更新しますか。',
            bulk_update_title:
                '%{name} を更新します。 |||| %{smart_count} %{name} を更新します。',
            delete_content: '削除しますか？',
            delete_title: '%{name} #%{id} を削除します',
            details: '詳細',
            error:
                "クライアント側でエラーが発生しました。",
            invalid_form: '入力内容が不十分です。エラー内容を確認してください。',
            loading: 'ロード中です。',
            no: 'いいえ',
            not_found:
                '遷移先のURLが存在しません。',
            yes: 'はい',
            unsaved_changes:
                "変更内容が保存されていませんが、破棄しても良いでしょうか。",
        },
        navigation: {
            no_results: '条件に該当するレコードは存在しません。',
            no_more_results:
                '%{page}ページ目は存在しません。前のページを確認してください。',
            page_out_of_boundaries: '%{page}ページ目は存在しません。',
            page_out_from_end: '最終ページです。',
            page_out_from_begin: '最初のページです。',
            page_range_info: '%{offsetBegin}-%{offsetEnd} / %{total}',
            page_rows_per_page: '1行あたりのレコード数:',
            next: '次へ',
            prev: '前へ',
            skip_nav: 'スキップ',
        },
        sort: {
            sort_by: '%{field} %{order}でソートします',
            ASC: '昇順',
            DESC: '降順',
        },
        auth: {
            auth_check_error: 'ログインしてください。',
            user_menu: 'プロフィール',
            username: 'ユーザー名',
            password: 'パスワード',
            sign_in: 'ログイン',
            sign_in_error: 'ユーザー名 または パスワードに誤りがあります。',
            logout: 'ログアウト',
        },
        notification: {
            updated: '更新しました。 |||| %{smart_count} 件更新しました。',
            created: '追加しました。',
            deleted: '削除しました。 |||| %{smart_count} 件削除しました。',
            bad_item: '正しい値ではありません。',
            item_doesnt_exist: '存在しません。',
            http_error: 'サーバーとの通信に失敗しました',
            data_provider_error:
                'データプロバイダーでエラーが発生しました。コンソールを確認してください。',
            i18n_error:
                '多言語ファイルが読み込めませんでした。',
            canceled: 'キャンセルしました。',
            logged_out: 'ログイン状態が解除されました。再度ログインしてください。',
            not_authorized: "許可されていません。",
        },
        validation: {
            required: '入力必須項目です。',
            minLength: '最低%{min}文字以上で入力してください。',
            maxLength: '最大%{max}文字以下で入力してください。',
            minValue: '%{min}以上で入力してください。',
            maxValue: '%{max}以下で入力してください。',
            number: '半角数字で入力してください。',
            email: 'Eメール形式で入力してください。',
            oneOf: '%{options} の中から選択してください。',
            regex: '正規表現にマッチする形で入力してください。: %{pattern}',
        },
    },
};

export default customEnglishMessages;
