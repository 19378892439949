import * as React from 'react';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import { Link } from 'react-router-dom';
import {useTranslate, useQueryWithStore, useGetList, useListContext} from 'react-admin';
import { subDays } from 'date-fns';

import CardWithIcon from './CardWithIcon';
import { Customer } from '../types';
import FiberNewIcon from "@material-ui/icons/FiberNew";
import {OrderStatusIds} from "../consts";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {stringify} from "query-string";


interface Props {
    deliveryMewOrders?: number;
    takeoutMewOrders?: number;
    deliverySchedules?: number;
    takeoutSchedules?: number;
}


const ScheduleSummary = (props: Props) => {
    const translate = useTranslate();
    const classes = useStyles();

    const { deliveryMewOrders, takeoutMewOrders } = props;
    const subtitle = <>
        <div>
            <Link
                to={{
                    pathname: '/delivery/orders',
                    search: stringify({
                        filter: JSON.stringify({ order_status: OrderStatusIds.ORDERED_ID }),
                    }),
                }}
            >
                {translate('view.dashboard.new_orders.delivery')} : {deliveryMewOrders ? deliveryMewOrders : 0}件
            </Link>
        </div>
        <div>
            <Link
                to={{
                    pathname: '/takeout/orders',
                    search: stringify({
                        filter: JSON.stringify({ order_status: OrderStatusIds.ORDERED_ID }),
                    }),
                }}
            >{translate('view.dashboard.new_orders.takeout')} : {takeoutMewOrders ? takeoutMewOrders : 0}件
            </Link>
        </div>
    </>

    const orderStatus = [
        { id: OrderStatusIds.ORDERED_ID, name: translate('view.schedules.tabs.show_ordered')
            , icon:<NewReleasesIcon />, color: "#ffb74d" },
        { id: OrderStatusIds.IN_PROGRESS_ID, name: translate('view.schedules.tabs.show_in_progress')
            , icon: <EmojiPeopleIcon />, color: "#4fc3f7"},
        { id: OrderStatusIds.FINISHED_ID, name: translate('view.schedules.tabs.show_finished')
            , icon: <CheckCircleIcon />, color: "#81c784"},
        { id: OrderStatusIds.CANCELED_ID, name: translate('view.schedules.tabs.show_canceled')
            , icon: <CancelIcon />, color: "#e57373"},
    ]

    //SEE: schedules/ResourceList
    const listContext = useListContext();
    // const totalCounts = useGetList(
    //     'delivery-takeout/schedules/total-count',
    //     { perPage: 1, page: 20 },
    //     { field: '', order: '' },
    //     {}
    // );

    console.log("---------------------------------------------------")

    return (
        <CardWithIcon
            to=""
            icon={FiberNewIcon}
            title={translate('view.dashboard.new_orders.title')}
            subtitle={subtitle}
        >
            <List>
                <ListItem
                    button
                    to="delivery/schedules"
                    component={Link}
                    key={1}
                >
                    <ListItemText
                        primary="デリバリー本日受付"
                    />
                </ListItem>
                {orderStatus.map(choice => (
                    <ListItem
                        button
                        to={{
                            pathname: '/delivery/schedules',
                            search: stringify({
                                filter: JSON.stringify({ order_status: choice.id }),
                            }),
                        }}
                        component={Link}
                        key={choice.id}
                        style={{
                            background : choice.color,
                            color: "white",
                        }}
                    >
                        <ListItemText
                            primary={`${choice.name} : ${choice.id + 3}件`}
                        />
                    </ListItem>
                ))}
            </List>
            <List>
                <ListItem
                    button
                    to="takeout/schedules"
                    component={Link}
                    key={1}
                >
                    <ListItemText
                        primary="テイクアウト本日受付"
                    />
                </ListItem>
                {orderStatus.map(choice => (
                    <ListItem
                        button
                        to={{
                            pathname: '/takeout/schedules',
                            search: stringify({
                                filter: JSON.stringify({ order_status: choice.id }),
                            }),
                        }}
                        component={Link}
                        key={choice.id}
                        style={{
                            background : choice.color,
                            color: "white",
                        }}
                    >
                        <ListItemText
                            primary={`${choice.name} : ${choice.id -1}件`}
                        />
                    </ListItem>
                ))}
            </List>
        </CardWithIcon>
    );
};

const useStyles = makeStyles(theme => ({
    link: {
        borderRadius: 0,
    },
    linkContent: {
        color: theme.palette.primary.main,
    },
}));

export default ScheduleSummary;
