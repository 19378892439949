import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
    DashboardMenuItem,
    MenuProps,
    ReduxState,
} from 'react-admin';

import { AppState } from '../../types';
import DeliveryMenu from "./DeliveryMenu";
import TakeoutMenu from "./TakeoutMenu";
import CommonMenu from "./CommonMenu";
import BrandMenu from "./BrandMenu";

const Index = ({ dense = false }: MenuProps) => {
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    return (
        <div
            className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            <DashboardMenuItem/>
            <DeliveryMenu/>
            <TakeoutMenu/>
            <CommonMenu/>
            <BrandMenu/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));

export default Index;
