import * as React from 'react';
import { ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import {
    linkToRecord,
    NumberField,
    useListContext,
    DatagridProps,
    Identifier, SelectField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import {PublishStatusChoices, PublishStatusIds, SaleTypeChoices, SaleTypeIds} from "../../consts";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import AddIcon from '@material-ui/icons/Add';
import {ClassNameMap} from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    notPublished: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(0,0,0,0.5)',
        color: 'white',
        textAlign: 'center',
        paddingTop: '50px',
    },
    price: {
        display: 'inline',
        fontSize: '1em',
    },
    link: {
        color: '#fff',
    },
    saleTypeIconWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        lineHeight: '20px',
        whiteSpace: 'initial',
        textOverflow: 'initial',
        fontSize: '14px',
    },
    tileImageWrapper: {
        width: "100%",
        height: "184px",
    },
    tileImage: {
        width: "100%",
        height: "184px",
        objectFit: "contain"

    }
}));

const getColsForWidth = (width: Breakpoint) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 4;
    if (width === 'md') return 4;
    if (width === 'lg') return 4;
    return 6;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = (props: GridProps & { nbItems?: number }) => {
    const { width, nbItems = 20 } = props;
    const classes = useStyles();
    return (
        <ImageList
            rowHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <ImageListItem key={key}>
                    <div className={classes.placeholder} />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const SaleTypeIconSelector = (saleType: number) => {
    switch (saleType) {
        case SaleTypeIds.DELIVERY_ID :
            return <span><LocalShippingIcon/></span>
        case SaleTypeIds.TAKEOUT_ID :
            return <span><LocalMallIcon/></span>
        case SaleTypeIds.DELIVERY_TAKEOUT_ID :
            return (<>
                <span><LocalShippingIcon/><AddIcon/><LocalMallIcon/></span>
            </>)
    }
}
const NotPublishMessage = (status: number, classes: ClassNameMap) => {
    switch (status) {
        case PublishStatusIds.NOT_PUBLISHED :
            return <span className={classes.notPublished}>非表示商品です。</span>;
        case PublishStatusIds.PUBLISHED :
            return  <></>;

    }
};
const LoadedGridList = (props: GridProps) => {
    const { width } = props;
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();

    if (!ids || !data) return null;

    return (
        <ImageList
            rowHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {ids.map((id: Identifier) => (
                <ImageListItem
                    // @ts-ignore
                    component={Link}
                    key={id}
                    to={linkToRecord(basePath, data[id].id)}
                >
                    { data[id].images.length !== 0 &&
                        <div className={classes.tileImageWrapper}>
                            <img src={data[id].images[0]['file_name']} alt=""  className={classes.tileImage}/>
                        </div>
                    }
                    {NotPublishMessage(data[id].status, classes)}
                    <ImageListItemBar
                        title={
                            <span className={classes.title}>
                                {data[id].name}
                            </span>}
                        subtitle={
                            <span className={classes.saleTypeIconWrapper}>
                                {SaleTypeIconSelector(data[id].sale_type)}
                                <NumberField
                                    className={classes.price}
                                    source="price"
                                    record={data[id]}
                                    color="inherit"
                                    options={{
                                        style: 'currency',
                                        currency: 'JPY',
                                    }}
                                />
                            </span>
                        }
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

interface GridProps extends Omit<DatagridProps, 'width'>, WithWidth {}

const defImageList = (props: WithWidth) => {
    const { width } = props;
    const { loaded } = useListContext();
    return loaded ? (
        <LoadedGridList width={width} />
    ) : (
        <LoadingGridList width={width} />
    );
};

export default withWidth()(defImageList);
