import ToppingIcon from '@material-ui/icons/AddBox';

import ResourceList from '../../../component/toppings/ResourceList';
import ResourceCreate from '../../../component/toppings/ResourceCreate';
import ResourceEdit from '../../../component/toppings/ResourceEdit';

const resource = {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    icon: ToppingIcon,
};

export default resource;
