import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
    Datagrid,
    DatagridProps,
    DateField,
    Identifier,
    List,
    ListContextProvider,
    ListProps,
    NumberField,
    ReferenceField,
    TextField,
    useGetList,
    useListContext,
    useTranslate,
    SelectField,
    ImageField,
    ArrayField,
    FunctionField,
    useResourceContext,
    useResourceDefinition,
} from 'react-admin';
import { Divider, Tabs, Tab, Theme, TableHead} from '@material-ui/core';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import { makeStyles } from '@material-ui/core/styles';
import {DeliveryMethod, OrderStatusIds, SaleTypeChoices} from "../../consts";
import { useLocation } from 'react-router-dom';

const orderFilters = [
    // <SearchInput source="q" alwaysOn />,
    // <ReferenceInput source="customer_id" reference="customers">
    //     <AutocompleteInput
    //         optionText={(choice: Customer) =>
    //             choice.id // the empty choice is { id: '' }
    //                 ? `${choice.first_name} ${choice.last_name}`
    //                 : ''
    //         }
    //     />
    // </ReferenceInput>,
    // <DateInput source="date_gte" />,
    // <DateInput source="date_lte" />,
    // <TextInput source="total_gte" />,
    // <NullableBooleanInput source="returned" />,
];

const useDatagridStyles = makeStyles({
    total: { fontWeight: 'bold' },
    image: {
        width: '100px',
        height: 'auto',
    },
    theadHide: {
        display: 'none',
    },
    headerCell: {
        backgroundColor: '#eeeeee',
    },
});

interface TabbedDatagridProps extends DatagridProps {}

const useGetTotals = (filterValues: any) => {
    let location = useLocation();
    const { total: totalOrdered } = useGetList(
        location.pathname.replace(/^\//, ''),
        { perPage: 1, page: 20 },
        { field: 'order_time', order: 'ASC' },
        { ...filterValues, order_status: OrderStatusIds.ORDERED_ID }
    );
    const { total: totalInProgress } = useGetList(
        location.pathname.replace(/^\//, ''),
        { perPage: 1, page: 20 },
        { field: 'order_time', order: 'ASC' },
        { ...filterValues, order_status: OrderStatusIds.IN_PROGRESS_ID }
    );
    const { total: totalFinished } = useGetList(
        location.pathname.replace(/^\//, ''),
        { perPage: 1, page: 20 },
        { field: 'order_time', order: 'ASC' },
        { ...filterValues, order_status: OrderStatusIds.FINISHED_ID }
    );
    const { total: totalCancelled } = useGetList(
        location.pathname.replace(/^\//, ''),
        { perPage: 1, page: 20 },
        { field: 'order_time', order: 'ASC' },
        { ...filterValues, order_status: OrderStatusIds.CANCELED_ID }
    );

    return {
        1: totalOrdered,
        4: totalInProgress,
        5: totalFinished,
        3: totalCancelled,
    };
};

const TabbedDatagrid = (props) => {
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const classes = useDatagridStyles();
    const [ordered, setOrdered] = useState<Identifier[]>([] as Identifier[]);
    const [inProgress, setInProgress] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const [finished, setFinished] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const [cancelled, setCancelled] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const totals = useGetTotals(filterValues) as any;
    useEffect(() => {
        if (ids) {
            switch (filterValues.order_status) {
                case OrderStatusIds.ORDERED_ID:
                    setOrdered(ids);
                    break;
                case OrderStatusIds.IN_PROGRESS_ID:
                    setInProgress(ids);
                    break;
                case OrderStatusIds.FINISHED_ID:
                    setFinished(ids);
                    break;
                case OrderStatusIds.CANCELED_ID:
                    setCancelled(ids);
                    break;
            }
        }
    }, [ids, filterValues.order_status]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, order_status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const selectedIds =
        filterValues.order_status === OrderStatusIds.ORDERED_ID ? ordered
            : filterValues.order_status === OrderStatusIds.IN_PROGRESS_ID ? inProgress
            : filterValues.order_status === OrderStatusIds.FINISHED_ID ? finished
            : cancelled;

    const translate = useTranslate();
    const tabs = [
        { id: OrderStatusIds.ORDERED_ID, name: translate('view.schedules.tabs.show_ordered')
                , icon:<NewReleasesIcon />, color: "#ffb74d" },
        { id: OrderStatusIds.IN_PROGRESS_ID, name: translate('view.schedules.tabs.show_in_progress')
            , icon: <EmojiPeopleIcon />, color: "#4fc3f7"},
        { id: OrderStatusIds.FINISHED_ID, name: translate('view.schedules.tabs.show_finished')
            , icon: <CheckCircleIcon />, color: "#81c784"},
        { id: OrderStatusIds.CANCELED_ID, name: translate('view.schedules.tabs.show_canceled')
            , icon: <CancelIcon />, color: "#e57373"},
    ]

    const dataGriddataGrid = <Datagrid
        {...props}
        optimized
        hasBulkActions={false}
        rowClick="edit"
    >
        <TextField
            source="order_time"
            label={
                props.delivery_method === DeliveryMethod.DELIVERY
                    ? "models.orders.order_time.delivery"
                    : "models.orders.order_time.takeout"
            }
        />
        <TextField
            source="customer_name"
            label="models.orders.customer_name"
        />
        {(() => {
            const authority = localStorage.getItem('authority');
            if (authority && ["0","12"].includes(authority)) {
                return (
                    <ReferenceField
                        source="brand_id"
                        reference="brands"
                        link={false}
                        label="models.products.brand"
                    >
                        <TextField source="name"/>
                    </ReferenceField>);
            }
        })()}
        <NumberField
            source="total"
            label="models.orders.total"
            options={{
                style: 'currency',
                currency: 'JPY',
            }}
        />
        <ArrayField
            source="order_items"
            label="models.orders.order_item"
        >
            <Datagrid
                classes={{ thead: classes.theadHide}}
            >
                <ReferenceField
                    source="product_id"
                    reference="delivery-takeout/products"
                    link={true}
                    label="models.order_items.product_image"
                >
                    <ImageField
                        source="images[0].file_name"
                        classes={{ image: classes.image}}
                    />
                </ReferenceField>

                <ReferenceField
                    source="product_id"
                    reference="delivery-takeout/products"
                    link={true}
                    label=""
                >
                    <TextField source="name" />
                </ReferenceField>

                <FunctionField
                    label="models.order_items.order_count"
                    render={record => `${record!.order_count} 個`}
                />
                <ArrayField
                    source="order_toppings"
                    label="models.order_items.order_toppings"
                >
                    <Datagrid
                        classes={{ headerCell: classes.headerCell}}
                    >
                        <ReferenceField
                            source="topping_id"
                            reference="delivery-takeout/toppings"
                            link={true}
                            label="models.order_toppings.topping_name"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <FunctionField
                            label="models.order_toppings.topping_count"
                            render={record => `${record!.topping_count} 個`}
                        />
                    </Datagrid>
                </ArrayField>
            </Datagrid>
        </ArrayField>
    </Datagrid>

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.order_status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.id]
                                ? `${choice.name} (${totals[choice.id]})`
                                : choice.name
                        }
                        icon={choice.icon}
                        value={choice.id}
                        style={{
                            background : choice.color,
                            color: "white",
                            display: "flex",
                            flexFlow: "wrap"
                        }}
                    />
                ))}
            </Tabs>
            <Divider />
                <div>
                    {filterValues.order_status === OrderStatusIds.ORDERED_ID && (
                        <ListContextProvider
                            value={{ ...listContext, ids: ordered }}
                        >
                            {dataGriddataGrid}
                        </ListContextProvider>
                    )}
                    {filterValues.order_status === OrderStatusIds.IN_PROGRESS_ID && (
                        <ListContextProvider
                            value={{ ...listContext, ids: inProgress }}
                        >
                            {dataGriddataGrid}
                        </ListContextProvider>
                    )}
                    {filterValues.order_status === OrderStatusIds.FINISHED_ID && (
                        <ListContextProvider
                            value={{ ...listContext, ids: finished }}
                        >
                            {dataGriddataGrid}
                        </ListContextProvider>
                    )}
                    {filterValues.order_status === OrderStatusIds.CANCELED_ID && (
                        <ListContextProvider
                            value={{ ...listContext, ids: cancelled }}
                        >
                            {dataGriddataGrid}
                        </ListContextProvider>
                    )}
                </div>
        </Fragment>
    );
};

const ResourceListTitle = (props) => {
    const translate = useTranslate();
    return <span>
        {translate(props.delivery_method_caption)}
        {translate(`common.component.submenu.delivery.schedules`)}
    </span>;
}
const ResourceList = (props: ListProps) => {
// @ts-ignore
    const {resource} = useResourceContext();
    const {options} = useResourceDefinition({resource: resource})
    return (
        <List
            title={<ResourceListTitle delivery_method_caption={options.delivery_method_caption}/>}
            {...props}
            filterDefaultValues={{ order_status: OrderStatusIds.ORDERED_ID }}
            sort={{ field: 'order_time', order: 'ASC' }}
            perPage={25}
            filters={orderFilters}
        >
            <TabbedDatagrid delivery_method={options.delivery_method}/>
        </List>

    )
};

export default ResourceList;
