// API接続情報
// @ts-ignore
export const API_SERVER_BASE_PATH = process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_API_SERVER_BASE_PATH;

// 公開状況
export const PublishStatusIds = {
  PUBLISHED: 1,
  NOT_PUBLISHED: 2
}
export const PublishStatusChoices = [
    { id: PublishStatusIds.PUBLISHED, name: '常に表示' },
    { id: PublishStatusIds.NOT_PUBLISHED, name: '常に非表示' },
]

// 注文可能タイプ
export const SaleTypeIds = {
    DELIVERY_ID: 500,
    TAKEOUT_ID: 501,
    DELIVERY_TAKEOUT_ID: 502,// 受注画面では利用しない
}
export const SaleTypeChoices = [
    { id: SaleTypeIds.DELIVERY_ID, name: 'デリバリー' },
    { id: SaleTypeIds.TAKEOUT_ID, name: 'テイクアウト' },
    { id: SaleTypeIds.DELIVERY_TAKEOUT_ID, name: 'テイクアウト / デリバリー' },
]

// 受取手段
export const DeliveryMethod = {
    DELIVERY: 'DELIVERY',
    DELIVERY_CAPTION: 'view.common.title.delivery',
    TAKEOUT: 'TAKEOUT',
    TAKEOUT_CAPTION: 'view.common.title.takeout',
}

// 受注対応状況 {管理画面path}/setting/system/masterdata/Eccube-Entity-Master-OrderStatus/edit
export const OrderStatusIds = {
    ORDERED_ID: 1,
    IN_PROGRESS_ID: 4,
    FINISHED_ID: 5,
    CANCELED_ID: 3,// （新規受付、対応中からの変更時は）注文取り消しを流用。対応完了時からの変更時はECCUBEのスタータス遷移が発送済みからは返品にしか変更できないため返品を流用
}
export const OrderStatusChoices = [
    { id: OrderStatusIds.ORDERED_ID, name: '新規受付' },
    { id: OrderStatusIds.IN_PROGRESS_ID, name: '対応中' },
    { id: OrderStatusIds.FINISHED_ID, name: '対応完了' },
    { id: OrderStatusIds.CANCELED_ID, name: 'キャンセル' },
]
// 英払い方法{管理画面path}/setting/shop/payment
export const PaymentMethodIds = {
    CREDIT_ID: 17,
    CASH_ON_TAKEOUT_ID: 19,
    INVOICE_PAYMENT_ID: 20,
    CASH_ON_DELIVERY_ID: 22,
    PAYPAY_ID: 23,
}
export const PaymentMethodChoices = [
    { id: PaymentMethodIds.CREDIT_ID, name: 'クレジット決済' },
    { id: PaymentMethodIds.CASH_ON_TAKEOUT_ID, name: '店頭受取時支払い' },
    { id: PaymentMethodIds.INVOICE_PAYMENT_ID, name: '請求書払い' },
    { id: PaymentMethodIds.CASH_ON_DELIVERY_ID, name: '配達受け取り時現金決済' },
    { id: PaymentMethodIds.PAYPAY_ID, name: 'PayPay決済' }
]

// メンバー稼働状況
export const WorkIds = {
    NON_ACTIVE: 0,
    ACTIVE: 1,
}
export const WorkChoices = [
    { id: WorkIds.NON_ACTIVE, name: '非稼働' },
    { id: WorkIds.ACTIVE, name: '稼働' },
]

// メンバー権限タイプ
export const AuthorityIds = {
    ADMIN: 10,
    OPERATOR: 11,
}
export const AuthorityChoices = [
    { id: AuthorityIds.ADMIN, name: '外部事業者_管理ユーザ' },
    { id: AuthorityIds.OPERATOR, name: '外部事業者_運用ユーザ' },
]

// PDF発行アクション
export const PdfCreateActions = {
    SEND_TO_CUSTOMER : 'sendToCustomer', //ユーザーメール送信,
    SEND_TO_OWN : 'sendToOwn',// 自身のメアドに送信
}
export const NameSurfixChoices = [
    { id: '様', name: '様' },
    { id: '御中', name: '御中' },
    { id: '　', name: 'なし' },
]

export const TaxRate = {
    NORMAL_TAX_RATE: "10",
    REDUCED_TAX_RATE: "8"
}
export const TaxRateChoices = [
    { id: TaxRate.NORMAL_TAX_RATE, name: `${TaxRate.NORMAL_TAX_RATE}%` },
    { id: TaxRate.REDUCED_TAX_RATE, name: `${TaxRate.REDUCED_TAX_RATE}%` },
]
