import * as React from 'react';
import {
    Create,
    FormTab,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    required,
    CreateProps,
    AutocompleteInput,
    useTranslate,
    ImageField,
    ImageInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayInput, useResourceContext,
} from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import {PublishStatusChoices, SaleTypeChoices, TaxRateChoices} from "../../consts";
import {useState} from "react";
import {Product} from "../../types";

export const styles = {
    price: { width: '7em' },
    width: { width: '7em' },
    height: { width: '7em' },
    stock: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
    hiddenInput: { display: 'none' },
};

const useStyles = makeStyles(styles);

interface ProductTitleProps {
    record?: Product;
}

const ProductTitle =  ({ record }: ProductTitleProps) =>{
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.products`)} #追加</span> : null;
}

const ResourceCreate = (props: CreateProps) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [stockDisabled, setStockDisabled] = useState<boolean>(false);
    return (
        <>
            <ResourceName />
            <Create
                {...props}
                title={<ProductTitle />}
            >
                <TabbedForm initialValues={{
                     minimum_purchase_amount: 1
                }}>
                    <FormTab
                        label="view.products.tabs.edit_product"
                    >
                        {(() => {
                            const authority = localStorage.getItem('authority');
                            if (authority && ["0","12"].includes(authority)) {
                                return (<ReferenceInput
                                    reference="brands"
                                    source="brand_id"
                                    fullWidth
                                    validate={required()}
                                    label="models.products.brand"
                                >
                                    <SelectInput optionText="name"/>
                                </ReferenceInput>);
                            } else {
                                return (<TextInput
                                    source="brand_id"
                                    defaultValue={localStorage.getItem('brand')}
                                    className={classes.hiddenInput}
                                >
                                </TextInput>);
                            }
                        })()}
                        <TextInput
                            source="name"
                            validate={required()}
                            fullWidth
                            label="models.products.name"
                        />
                        <ImageInput
                            source="upload_images"
                            accept="image/*"
                            maxSize={500000}
                            multiple={true}
                            label="models.products.image"
                            placeholder={translate("view.products.upload_image")}
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                        <NumberInput
                            source="price"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ¥
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            validate={required()}
                            label="models.products.price"
                            format={v => Math.trunc(v)}
                        />
                        <AutocompleteInput
                            source="tax_rate"
                            choices={TaxRateChoices}
                            validate={required()}
                            fullWidth
                            label="models.products.tax_rate"
                        />
                        <NumberInput
                            source="stock"
                            fullWidth
                            label="models.products.stock"
                            disabled={stockDisabled}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        個
                                    </InputAdornment>
                                ),
                            }}
                            format={v => Math.trunc(v)}
                        />
                        <BooleanInput
                            source="stock_unlimited"
                            fullWidth
                            label="models.products.stock_unlimited"
                            onChange={event => setStockDisabled(event)}
                        />
                        <ReferenceArrayInput
                            reference="delivery-takeout/categories"
                            source="categories"
                            fullWidth
                            label="models.products.category"
                        >
                            <AutocompleteArrayInput />
                        </ReferenceArrayInput>
                        <ReferenceInput
                            source="topping_group_id"
                            reference="delivery-takeout/topping-groups"
                            fullWidth
                            label="models.products.topping_group"
                            allowEmpty
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <RichTextInput
                            source="catalog_product_detail"
                            label="models.products.catalog_product_detail"
                        />
                    </FormTab>
                    <FormTab
                        label="view.products.tabs.edit_sale"
                        path="details"
                    >
                        <AutocompleteInput
                            source="status"
                            choices={PublishStatusChoices}
                            validate={required()}
                            fullWidth
                            label="models.products.status"
                        />
                        <AutocompleteInput
                            source="sale_type"
                            choices={SaleTypeChoices}
                            validate={required()}
                            fullWidth
                            label="models.products.sale_type"
                        />
                        <NumberInput
                            source="display_order"
                            validate={required()}
                            fullWidth
                            label="models.products.display_order"
                        />
                        <NumberInput
                            source="minimum_purchase_amount"
                            fullWidth
                            validate={required()}
                            label="models.products.minimum_purchase_amount"
                        />
                        <TextInput
                            source="precautionary_statement"
                            fullWidth
                            label="models.products.precautionary_statement"
                        />
                        <NumberInput
                            source="sale_limit_by_delivery_date"
                            fullWidth
                            label="models.products.sale_limit_by_delivery_date"
                        />
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    );
};

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

export default ResourceCreate;
