import ToppingGroupIcon from '@material-ui/icons/AddToPhotos';

import ResourceList from '../../../component/topping-groups/ResourceList';
import ResourceCreate from '../../../component/topping-groups/ResourceCreate';
import ResourceEdit from '../../../component/topping-groups/ResourceEdit';

const resource = {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    icon: ToppingGroupIcon,
};

export default resource;
