import OrderIcon from '@material-ui/icons/Receipt';

import OrderList from '../../../component/orders/ResourceList';
import OrderEdit from '../../../component/orders/ResourceEdit';
import {DeliveryMethod} from "../../../consts";

const resource = {
    list: OrderList,
    edit: OrderEdit,
    icon: OrderIcon,
    options: {
        delivery_method: DeliveryMethod.TAKEOUT,
        delivery_method_caption: DeliveryMethod.TAKEOUT_CAPTION,},
};

export default resource;