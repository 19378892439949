import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    ListProps,
    TextField,
    NumberField,
    SearchInput,
    useTranslate,
    SelectField,
    AutocompleteInput,
    useResourceContext,
    useResourceDefinition,
    DateInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput, ReferenceField
} from 'react-admin';
import { ReactElement } from 'react';
import {DeliveryMethod, OrderStatusChoices, PaymentMethodChoices, SaleTypeChoices} from '../../consts';
import {calculateTodayOrders} from "../../utils";

const ResourceList = (props: ListProps): ReactElement => {
    // @ts-ignore
    const {resource} = useResourceContext();
    const {options} = useResourceDefinition({resource: resource})

    return (
        <>
            <>{resource}</>
            <List
                title={<ResourceListTitle delivery_method_caption={options.delivery_method_caption}/>}
                {...props}
                filters={ResourceListFilters}
                sort={{field: 'id', order: 'DESC'}}
                perPage={25}
                bulkActionButtons={false}
                // actions={<ResourceListActions/>}
            >
                <Datagrid optimized rowClick="edit">
                    <NumberField
                        source="id"
                        label="models.orders.id"
                    />
                    <DateField
                        source="order_date"
                        locales="ja-JP"
                        label={
                            options.delivery_method === DeliveryMethod.DELIVERY
                            ? "models.orders.order_date.delivery"
                            : "models.orders.order_date.takeout"
                        }
                    />
                    <TextField
                        source="order_time"
                        label={
                            options.delivery_method === DeliveryMethod.DELIVERY
                                ? "models.orders.order_time.delivery"
                                : "models.orders.order_time.takeout"
                        }
                    />
                    {(() => {
                        const authority = localStorage.getItem('authority');
                        if (authority && ["0","12"].includes(authority)) {
                            return (
                                <ReferenceField
                                    source="brand_id"
                                    reference="brands"
                                    link={false}
                                    label="models.products.brand"
                                >
                                    <TextField source="name"/>
                                </ReferenceField>);
                        }
                    })()}
                    <SelectField
                        source="order_status"
                        choices={OrderStatusChoices}
                        label="models.orders.order_status"
                    />
                    <SelectField
                        source="payment_method"
                        choices={PaymentMethodChoices}
                        label="models.orders.payment_method"
                    />
                    <TextField
                        source="customer_name"
                        label="models.orders.customer_name"
                    />
                    <TextField
                        source="customer_email"
                        label="models.orders.customer_email"
                    />
                    <NumberField
                        source="total"
                        options={{
                            style: 'currency',
                            currency: 'JPY',
                        }}
                        label="models.orders.total"
                    />
                    <DateField
                        source="create_date"
                        showTime
                        locales="ja-JP"
                        label="models.orders.create_date"
                    />
                </Datagrid>
            </List>
        </>
    );
};


const ResourceListTitle = (props) => {
    const translate = useTranslate();
    return <span>
        {translate(props.delivery_method_caption)}
        {translate(`common.component.submenu.delivery.orders`)}
    </span>;
}
const ResourceListFilters = [
    <SearchInput source="customer_name" placeholder="顧客名検索" alwaysOn />,
    <AutocompleteInput
        source="order_status"
        choices={OrderStatusChoices}
        label="models.orders.order_status"
    />,
    <AutocompleteInput
        source="payment_id"
        choices={PaymentMethodChoices}
        label="models.orders.payment_method"
    />,
    <DateInput
        source="create_date_gte"
        label="models.orders.create_date"
    />,
];
export default ResourceList;
