import * as React from 'react';
import {
    AutocompleteInput,
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    FieldProps,
    NumberInput,
    ReferenceInput,
    SelectInput, useEditController, BooleanInput, useResourceContext, FunctionField,
} from 'react-admin';
import {Box, Card, CardContent, InputAdornment} from '@material-ui/core';

import {Topping} from '../../types';
import {PublishStatusChoices, TaxRateChoices} from '../../consts';
import {useState} from "react";

const ResourceEdit = (props: EditProps) => {
    const {record} = useEditController<Topping>(props);
    const [stockDisabled, setStockDisabled] = useState<boolean>(record ? record.stock_unlimited : false);

    if (!record || record === undefined) {
        return null;
    }
    return (
        <>
            <ResourceName />
            <Edit
                title={<ResourceTitle />}
                component="div"
                undoable={false}
                {...props}
            >
                <FormWithRedirect
                    {...props}
                    render={(formProps: any) => (
                        <Card>
                            <form>
                                <CardContent>
                                    <Box display={{ md: 'block', lg: 'flex' }}>
                                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                            <TextInput
                                                source="name"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.toppings.name"
                                            />
                                            <NumberInput
                                                source="price"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            ¥
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                validate={requiredValidate}
                                                label="models.toppings.price"
                                            />
                                            <AutocompleteInput
                                                source="tax_rate"
                                                choices={TaxRateChoices}
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.products.tax_rate"
                                            />
                                            <FunctionField
                                                label="models.toppings.price_in_tax"
                                                render={record => `税込金額 : ¥${record!.price_in_tax}`}
                                            />
                                            <NumberInput
                                                source="stock"
                                                disabled={stockDisabled}
                                                fullWidth
                                                label="models.toppings.stock"
                                            />
                                            <BooleanInput
                                                source="stock_unlimited"
                                                fullWidth
                                                label="models.toppings.stock_unlimited"
                                                onChange={event => setStockDisabled(event)}
                                            />
                                            <ReferenceInput
                                                source="topping_group_id"
                                                reference="delivery-takeout/topping-groups"
                                                fullWidth
                                                label="models.toppings.topping_group"
                                                allowEmpty
                                            >
                                                <SelectInput optionText="name" />
                                            </ReferenceInput>
                                            <NumberInput
                                                source="display_order"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.toppings.display_order"
                                            />
                                            <AutocompleteInput
                                                source="status"
                                                choices={PublishStatusChoices}
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.toppings.status"
                                            />
                                        </Box>
                                    </Box>
                                </CardContent>
                                <Toolbar
                                    record={formProps.record}
                                    basePath={formProps.basePath}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    saving={formProps.saving}
                                />
                            </form>
                        </Card>
                    )}
                />
            </Edit>
        </>
    );
};


const ResourceTitle = ({ record }: FieldProps<Topping>) => {
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.toppings`)} #{record.name}</span> : null;
}


const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const requiredValidate = [required()];

export default ResourceEdit;
