// スケジュール用リソースの本日日付取得条件
import {useDataProvider, useGetList, useVersion} from "react-admin";
import {
    startOfMonth,
    format
} from 'date-fns';
import {DeliveryMethod, OrderStatusIds} from "./consts";
import {Order} from "./types";
interface OrderStats {
    revenue: number;
    orderOrderedCount: number;
    orderInProgressCount: number;
    orderFinishedCount: number;
    orderCanceledCount: number;
}
// 受注関連の計算
export const calculateTodayOrders = async (deliveryMethod: string): Promise<{[index: string]: number}> => {
    const dataProvider = useDataProvider();
    const resource = DeliveryMethod.DELIVERY === deliveryMethod
        ? 'delivery/schedules' : 'takeout/schedules';

    const {data: todayOrders} = await dataProvider.getList<Order>(
        resource,
        {
            filter: {},
            sort: {field: 'date', order: 'DESC'},
            pagination: {page: 1, perPage: 50},
        }
    );

    return todayOrders
        .reduce(
            (stats: OrderStats, order) => {
                switch (order.order_status) {
                    case OrderStatusIds.ORDERED_ID:
                        stats.orderOrderedCount++;
                        break;
                    case OrderStatusIds.IN_PROGRESS_ID:
                        stats.orderInProgressCount++;
                        break;
                    case OrderStatusIds.FINISHED_ID:
                        stats.orderFinishedCount++;
                        break;
                    case OrderStatusIds.CANCELED_ID:
                        stats.orderCanceledCount++;
                        break;
                }
                stats.revenue += order.total;
                return stats;
            },
            {
                revenue: 0,
                orderOrderedCount: 0,
                orderInProgressCount: 0,
                orderFinishedCount: 0,
                orderCanceledCount: 0,
            }
        );
}
export const calculateThisMonthOrders = () => {
    const todayOrderList = useGetList(
        'delivery/orders',
        undefined,
        undefined,
        {
            create_date_gte: format(startOfMonth(new Date()), 'YYYY/MM/DD HH:mm:ss'),
            create_date_lte: undefined,
        }
    );
}