import ScheduleIcon from '@material-ui/icons/Today';

import ResourceList from '../../../component/schedules/ResourceList';
import ScheduleEdit from '../../../component/orders/ResourceEdit';
import {DeliveryMethod} from "../../../consts";

const resource = {
    list: ResourceList,
    edit: ScheduleEdit,
    icon: ScheduleIcon,
    options: {
        delivery_method: DeliveryMethod.DELIVERY,
        delivery_method_caption: DeliveryMethod.DELIVERY_CAPTION,
    },
};

export default resource;