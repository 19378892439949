import * as React from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    BooleanInput,
    useResourceContext,
    Toolbar,
    FieldProps, useTranslate,
} from 'react-admin';
import { InputAdornment} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import {PublishStatusChoices, TaxRateChoices} from "../../consts";
import {useState} from "react";
import {Topping} from "../../types";

export const styles = {
    name: {},
    brand: {},
    hiddenInput: { display: 'none' },
};

const useStyles = makeStyles(styles);

const ResourceCreate = (props: CreateProps) => {
    const classes = useStyles();
    const [stockDisabled, setStockDisabled] = useState<boolean>(false);

    return (
        <>
            <ResourceName />
            <Create
                {...props}
                title={<ResourceTitle />}
            >
                <SimpleForm>
                    {(() => {
                        const authority = localStorage.getItem('authority');
                        if (authority && ["0","12"].includes(authority)) {
                            return (<ReferenceInput
                                reference="brands"
                                source="brand_id"
                                fullWidth
                                validate={required()}
                                label="models.products.brand"
                            >
                                <SelectInput optionText="name"/>
                            </ReferenceInput>);
                        } else {
                            return (<TextInput
                                source="brand_id"
                                defaultValue={localStorage.getItem('brand')}
                                className={classes.hiddenInput}
                            >
                            </TextInput>);
                        }
                    })()}
                    <TextInput
                        autoFocus
                        source="name"
                        validate={requiredValidate}
                        fullWidth
                        label="models.toppings.name"
                    />
                    <NumberInput
                        source="price"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ¥
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        validate={requiredValidate}
                        label="models.toppings.price"
                    />
                    <AutocompleteInput
                        source="tax_rate"
                        choices={TaxRateChoices}
                        validate={requiredValidate}
                        fullWidth
                        label="models.products.tax_rate"
                    />
                    <NumberInput
                        source="stock"
                        disabled={stockDisabled}
                        fullWidth
                        label="models.toppings.stock"
                    />
                    <BooleanInput
                        source="stock_unlimited"
                        fullWidth
                        label="models.toppings.stock_unlimited"
                        onChange={event => setStockDisabled(event)}
                    />
                    <ReferenceInput
                        source="topping_group_id"
                        reference="delivery-takeout/topping-groups"
                        fullWidth
                        label="models.toppings.topping_group"
                        allowEmpty
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput
                        source="display_order"
                        validate={requiredValidate}
                        fullWidth
                        label="models.toppings.display_order"
                    />
                    <AutocompleteInput
                        source="status"
                        choices={PublishStatusChoices}
                        validate={requiredValidate}
                        fullWidth
                        label="models.toppings.status"
                    />
                </SimpleForm>
            </Create>
        </>
    );
};
const ResourceTitle = ({ record }: FieldProps<Topping>) => {
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.toppings`)} #追加</span> : null;
}

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
const requiredValidate = [required()];

export default ResourceCreate;
