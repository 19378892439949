import * as React from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    FieldProps, useResourceContext, NumberInput, AutocompleteInput, BooleanInput, ReferenceInput, SelectInput,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import { ToppingGroup } from '../../types';
import {AuthorityChoices, PublishStatusChoices, WorkChoices} from "../../consts";

const ResourceEdit = (props: EditProps) => {
    return (
        <>
            <ResourceName />
            <Edit
                title={<ResourceTitle />}
                component="div"
                undoable={false}
                {...props}
            >
                <FormWithRedirect
                    {...props}
                    render={(formProps: any) => (
                        <Card>
                            <form>
                                <CardContent>
                                    <Box display={{ md: 'block', lg: 'flex' }}>
                                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                            <TextInput
                                                source="name"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.name"
                                            />
                                            <TextInput
                                                source="department"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.department"
                                            />
                                            <TextInput
                                                source="login_id"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.login_id"
                                            />
                                            <AutocompleteInput
                                                source="authority"
                                                choices={AuthorityChoices}
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.authority"
                                            />
                                            <AutocompleteInput
                                                source="work"
                                                choices={WorkChoices}
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.work"
                                            />
                                            <ReferenceInput
                                                source="brand"
                                                reference="brands"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.members.brand"
                                            >
                                                <SelectInput optionText="identifier" />
                                            </ReferenceInput>
                                        </Box>
                                    </Box>
                                </CardContent>
                                <Toolbar
                                    record={formProps.record}
                                    basePath={formProps.basePath}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    saving={formProps.saving}
                                />
                            </form>
                        </Card>
                    )}
                />
            </Edit>
        </>
    );
};

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const ResourceTitle = ({ record }: FieldProps<ToppingGroup>) => {
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.brand.members`)} #{record.name}</span> : null;
}

const requiredValidate = [required()];

export default ResourceEdit;
