import * as React from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    useTranslate,
    required, useResourceContext, AutocompleteInput, ReferenceInput, SelectInput,
} from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import {AuthorityChoices, WorkChoices} from "../../consts";
import {Box} from "@material-ui/core";

export const styles: Styles<Theme, any> = {
    name: {},
    brand: {},
};

const useStyles = makeStyles(styles);

const ResourceCreate = (props: CreateProps) => {
    const classes = useStyles(props);
    return (
        <>
            <ResourceName />
            <Create {...props}>
                <SimpleForm>
                    <TextInput
                        source="name"
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.name"
                    />
                    <TextInput
                        source="department"
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.department"
                    />
                    <TextInput
                        source="login_id"
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.login_id"
                    />
                    <AutocompleteInput
                        source="authority"
                        choices={AuthorityChoices}
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.authority"
                    />
                    <AutocompleteInput
                        source="work"
                        choices={WorkChoices}
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.work"
                    />
                    <ReferenceInput
                        source="brand"
                        reference="brands"
                        validate={requiredValidate}
                        formClassName={classes.name}
                        fullWidth
                        label="models.members.brand"
                    >
                        <SelectInput optionText="identifier" />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        </>
    );
};

const requiredValidate = [required()];

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
export default ResourceCreate;
