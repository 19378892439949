import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, BooleanInput } from 'react-admin';
import {Product, ProductImage} from '../../types';

const useStyles = makeStyles({
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2, height: '100%' },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    imgWrapper: {display: 'flex', flexFlow: 'wrap'},
    cardWrapper: {display: 'flex', flexFlow: 'column'},
    img: {
        width: '19em',
        height: '15em',
        objectFit: 'contain'
    },
});
interface Props {
    images: ProductImage[];
    deleteImage: (event:React.ChangeEvent, is:string) => void ;
}
const ResourceImageCard = (props: Props) => {
    const { images } = props;
    const { deleteImage } = props;
    const classes = useStyles();

    return (
        <div className={classes.imgWrapper}>
            {images && images.map((productImage) =>
                <div className={classes.cardWrapper} key={productImage.id}>
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <img src={productImage.file_name} alt="" className={classes.img} />
                        </CardContent>
                    </Card>
                    <BooleanInput
                        label="削除する"
                        source={"test" + productImage.id.toString()}
                        defaultValue={false}
                        onChange={(event) => deleteImage(event, productImage.id.toString())}
                    />
                </div>
            )}
        </div>
    );
};

export default ResourceImageCard;
