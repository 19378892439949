import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    useTranslate,
    FormWithRedirect, TextField,
    RadioButtonGroupInput, FunctionField, SimpleShowLayout, useDataProvider, useMutation,
} from 'react-admin';
import SendIconContent from '@material-ui/icons/SendSharp';
import DownloadIconContent from '@material-ui/icons/PictureAsPdf';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DialogContent, DialogActions, DialogContentText} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {API_SERVER_BASE_PATH, NameSurfixChoices, PdfCreateActions} from "../../consts";

const DOCS_CAPTION = {
    NAME_SURFIX: "view.orders.doc.name_surfix",
    RECEIPT: {
        buttonLabel: "view.orders.doc.receipt.create",
        name: "view.orders.doc.receipt.name",
        message: "view.orders.doc.receipt.message",
    },
    INVOICE: {
        buttonLabel: "view.orders.doc.invoice.create",
        name: "view.orders.doc.invoice.name",
        message: "view.orders.doc.invoice.message",
    }
}
const useStyles = makeStyles(theme => ({
    hiddenInput: {
        display: 'none',
    }
}));

function CreateDocModalButton({ onChange, kind, orderId, message, memo }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('delivery-takeout/orders/doc-send');
    const notify = useNotify();
    const form = useForm();
    const translate = useTranslate();
    const classes = useStyles();

    const [sendToHidden, setSendToHidden] = useState<boolean>(true);

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    notify('ra.action.sent');
                    setShowDialog(false);
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} label={DOCS_CAPTION[kind].buttonLabel}>
                <SendIconContent />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={DOCS_CAPTION[kind].buttonLabel}
            >
                <DialogTitle>{translate(DOCS_CAPTION[kind].buttonLabel)}</DialogTitle>

                <FormWithRedirect
                    resource="delivery-takeout/orders/doc-send"
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 saving
                             }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    source="order_id"
                                    fullWidth
                                    defaultValue={orderId}
                                    className={classes.hiddenInput}
                                />
                                <TextInput
                                    source="kind"
                                    fullWidth
                                    defaultValue={kind}
                                    className={classes.hiddenInput}
                                />
                                <TextInput
                                    source="name"
                                    label={DOCS_CAPTION[kind].name}
                                    validate={required()}
                                    fullWidth
                                />
                                <RadioButtonGroupInput
                                    lavel={DOCS_CAPTION.NAME_SURFIX}
                                    source="name_surfix"
                                    validate={required()}
                                    choices={NameSurfixChoices}
                                    defaultValue="様"
                                />
                                <TextInput
                                    source="message"
                                    label={DOCS_CAPTION[kind].message}
                                    validate={required()}
                                    fullWidth
                                />
                                {!sendToHidden && (
                                    <TextInput
                                        source="send_to"
                                        label="view.orders.doc.send_to"
                                        validate={required()}
                                        fullWidth
                                    />
                                )}
                                <RadioButtonGroupInput
                                    source="action"
                                    defaultValue={PdfCreateActions.SEND_TO_CUSTOMER}
                                    choices={[
                                        { id: PdfCreateActions.SEND_TO_CUSTOMER, name: 'お客様へ送信' },
                                        { id: PdfCreateActions.SEND_TO_OWN, name: '任意のメールアドレスに送信' },
                                    ]}
                                    validate={required()}
                                    onChange={event => setSendToHidden(() => {
                                        return event.toString() == PdfCreateActions.SEND_TO_CUSTOMER
                                    })}
                                />
                            </DialogContent>

                            <SimpleShowLayout>
                                <TextField
                                    source="memo"
                                    label="models.orders.memo"
                                />
                            </SimpleShowLayout>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    label="ra.action.pdf_create"
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    saving={saving}
                                    disabled={loading}
                                    icon={<DownloadIconContent />}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default CreateDocModalButton;
