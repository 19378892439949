import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CategoryIcon from '@material-ui/icons/Category';
import PublicIcon from '@material-ui/icons/Public';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {PublishStatusChoices, SaleTypeChoices} from "../../consts";

import {
    FilterList,
    FilterListItem,
    FilterLiveSearch, ReferenceInput, required, SearchInput, SelectInput, TextInput,
    useGetList,
} from 'react-admin';

import { Category } from '../../types';
import {useState} from "react";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const Aside = () => {
    const { data, ids } = useGetList<Category>(
        'brands',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
        {}
    );
    const [state, setState] = useState({
        index: true,
    });
    const handleToggle = (filter: "ïnde") => {
        setState(state => ({ ...state, [filter]: !state[filter] }));
    };
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <FilterLiveSearch
                    // @ts-ignore
                    label="商品名検索"
                    source="name"
                />

                {(() => {
                    const authority = localStorage.getItem('authority');
                    if (authority && ["0","12"].includes(authority)) {
                        return (
                            <FilterList
                                label="view.products.brand"
                                icon={<CategoryIcon />}
                            >
                                {ids &&
                                    data &&
                                    ids.map((id: any) => (
                                        <FilterListItem
                                            label={inflection.humanize(data[id].name)}
                                            key={data[id].id}
                                            value={{ brands: [data[id].id]}}
                                        />
                                    ))}
                            </FilterList>
                        );
                    }
                })()}

                <FilterList
                    label="view.products.sale_type"
                    icon={<LocalShippingIcon />}
                >
                    {
                        SaleTypeChoices.map((item: any) => (
                            <FilterListItem
                                label={inflection.humanize(item.name)}
                                key={item.id}
                                value={{ sale_type: item.id }}
                            />
                        ))}
                </FilterList>
                <FilterList
                    label="view.products.status"
                    icon={<PublicIcon />}
                >
                    {
                        PublishStatusChoices.map((item: any) => (
                            <FilterListItem
                                label={inflection.humanize(item.name)}
                                key={item.id}
                                value={{ status: item.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;
