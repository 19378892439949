import * as React from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    useTranslate,
    required, useResourceContext, FieldProps, ReferenceInput, SelectInput,
} from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import {ToppingGroup} from "../../types";

export const styles = {
    name: {},
    brand: {},
    hiddenInput: { display: 'none' },
};

const useStyles = makeStyles(styles);

const ResourceCreate = (props: CreateProps) => {
    const classes = useStyles(props);
    return (
        <>
            <ResourceName />
            <Create
                {...props}
                title={<ResourceTitle />}
            >
                <SimpleForm
                    initialValues={{
                        name: '',
                    }}
                >
                    {(() => {
                        const authority = localStorage.getItem('authority');
                        if (authority && ["0","12"].includes(authority)) {
                            return (<ReferenceInput
                                reference="brands"
                                source="brand_id"
                                fullWidth
                                validate={required()}
                                label="models.products.brand"
                            >
                                <SelectInput optionText="name"/>
                            </ReferenceInput>);
                        } else {
                            return (<TextInput
                                source="brand_id"
                                defaultValue={localStorage.getItem('brand')}
                                className={classes.hiddenInput}
                            >
                            </TextInput>);
                        }
                    })()}
                    <TextInput
                        autoFocus
                        source="name"
                        formClassName={classes.name}
                        validate={requiredValidate}
                        fullWidth
                        label="models.topping_groups.name"
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

const requiredValidate = [required()];

const ResourceTitle = ({ record }: FieldProps<ToppingGroup>) => {
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.topping_groups`)} #追加</span> : null;
}
const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
export default ResourceCreate;
