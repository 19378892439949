import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    CreateButton,
    ListProps,
    TopToolbar,
    TextField,
    NumberField,
    SearchInput,
    useTranslate,
    useResourceContext,
} from 'react-admin';
import { ReactElement } from 'react';

const ResourceListTitle = () => {
    const translate = useTranslate();
    return <span>{translate(`common.component.submenu.delivery_takeout.topping_groups`)}</span>;
}
const ResourceListActions = ( ) => (
    <TopToolbar>
        <CreateButton basePath={"topping-groups"} />
    </TopToolbar>
);
const ResourceListFilters = [
    <SearchInput source="name" placeholder="トッピング名検索" alwaysOn />,
];
const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <List
            title={<ResourceListTitle />}
            {...props}
            filters={ResourceListFilters}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            actions={<ResourceListActions/>}
        >
            <>
                <ResourceName />
                <Datagrid optimized rowClick="edit" >
                    <NumberField
                        source="id"
                        label="models.topping_groups.id"
                    />
                    <TextField
                        source="name"
                        label="models.topping_groups.name"
                    />
                </Datagrid>
            </>
        </List>
    );
};

export default ResourceList;
