import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    CreateButton,
    ListProps,
    TopToolbar,
    TextField,
    NumberField,
    SearchInput, useTranslate, SelectField, ReferenceField, useResourceContext,
} from 'react-admin';
import { ReactElement } from 'react';
import {PublishStatusChoices} from '../../consts';

const ResourceListTitle = () => {
    const translate = useTranslate();
    return <span>{translate(`common.component.submenu.delivery_takeout.toppings`)}</span>;
}
const ResourceListActions = ( ) => (
    <TopToolbar>
        <CreateButton basePath={"toppings"} />
    </TopToolbar>
);
const ResourceListFilters = [
    <SearchInput source="name" placeholder="トッピング名検索" alwaysOn />,
];

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
const ResourceList = (props: ListProps): ReactElement => {
    return (
        <List
            title={<ResourceListTitle />}
            {...props}
            filters={ResourceListFilters}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            actions={<ResourceListActions/>}
        >
            <>
                <ResourceName />
                <Datagrid optimized rowClick="edit">
                    <NumberField
                        source="id"
                        label="models.toppings.id"
                    />
                    <TextField
                        source="name"
                        label="models.toppings.name"
                    />
                    <NumberField
                        source="price"
                        label="models.toppings.price"
                        options={{
                            style: 'currency',
                            currency: 'JPY',
                        }}
                    />
                    <NumberField
                        source="stock"
                        label="models.toppings.stock"
                    />
                    <ReferenceField
                        source="topping_group_id"
                        reference="delivery-takeout/topping-groups"
                        link={true}
                        label="models.toppings.topping_group"
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField
                        source="display_order"
                        label="models.toppings.display_order"
                    />
                    <SelectField
                        source="status"
                        choices={PublishStatusChoices}
                        label="models.toppings.status"
                    />
                </Datagrid>
            </>
        </List>
    );
};

export default ResourceList;
