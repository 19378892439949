import ProductIcon from '@material-ui/icons/Fastfood';
import ResourceList from '../../../component/products/ResourceList';
import ResourceEdit from '../../../component/products/ResourceEdit';
import ResourceCreate from '../../../component/products/ResourceCreate';

const resource = {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    icon: ProductIcon,
};

export default resource;