import * as React from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Edit,
    EditProps,
    FormTab, FunctionField,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceArrayInput, ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm, TextField,
    TextInput, useEditController, useResourceContext, useTranslate,
} from 'react-admin';
import {InputAdornment} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';

import ResourceImageCard from './ResourceImageCard';
import { styles as createStyles } from './ResourceCreate';
import {Product, ProductImage} from '../../types';
import {PublishStatusChoices, SaleTypeChoices, TaxRateChoices} from "../../consts";
import {useState} from "react";

interface ProductTitleProps {
    record?: Product;
}

const ProductTitle =  ({ record }: ProductTitleProps) =>{
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.products`)} #{record.name}</span> : null;
}


const useStyles = makeStyles({
    ...createStyles,
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tab: {
        maxWidth: '40em',
        display: 'block',
    },
});
// 一度しか作成させないためにここで宣言
const delete_image_ids: string[]  = [];
const deleteImage = (event:React.ChangeEvent, id:string):void => {
    if (event) {
        delete_image_ids.push(id);
    } else {
        delete_image_ids.splice(delete_image_ids.indexOf(id), 1);
    }
};

const ResourceEdit = (props: EditProps) => {
    const classes = useStyles();
    const translate = useTranslate();

    const {record} = useEditController<Product>(props);
    const [stockDisabled, setStockDisabled] = useState<boolean>(record ? record.stock_unlimited : false);
    const transform = data => ({
        ...data,
        delete_images: delete_image_ids
    });

    if (!record || record === undefined) {
        return null;
    }

    // @ts-ignore
    return (
        <>
            <ResourceName />
            <Edit
                {...props}
                title={<ProductTitle />}
                undoable={false}
                transform={transform}
            >
                <TabbedForm>
                    <FormTab
                        label="view.products.tabs.edit_product"
                        contentClassName={classes.tab}
                        path=""
                    >
                        <ReferenceField
                            source="brand_id"
                            reference="brands"
                            link={false}
                            label="models.products.brand"
                        >
                            <TextField source="name"/>
                        </ReferenceField>
                        <TextInput
                            source="name"
                            validate={requiredValidate}
                            fullWidth
                            label="models.products.name"
                        />
                        <ResourceImageCard
                            images={record.images}
                            deleteImage={deleteImage}
                        />
                        <ImageInput
                            source="upload_images"
                            accept="image/*"
                            maxSize={500000}
                            multiple={true}
                            label="models.products.image"
                            placeholder={translate("view.products.upload_image")}
                        >
                            <ImageField source="src" title="title" />
                        </ImageInput>
                        <NumberInput
                            source="price"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ¥
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            validate={requiredValidate}
                            label="models.products.price"
                            format={v => Math.trunc(v)}
                        />
                        <AutocompleteInput
                            source="tax_rate"
                            choices={TaxRateChoices}
                            validate={requiredValidate}
                            fullWidth
                            label="models.products.tax_rate"
                        />
                        <FunctionField
                            label="models.products.price_in_tax"
                            render={record => `¥${record!.price_in_tax}`}
                        />
                        <NumberInput
                            source="stock"
                            fullWidth
                            label="models.products.stock"
                            disabled={stockDisabled}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        個
                                    </InputAdornment>
                                ),
                            }}
                            format={v => Math.trunc(v)}
                        />
                        <BooleanInput
                            source="stock_unlimited"
                            fullWidth
                            label="models.products.stock_unlimited"
                            onChange={event => setStockDisabled(event)}
                        />
                        <ReferenceArrayInput
                            reference="delivery-takeout/categories"
                            source="categories"
                            fullWidth
                            label="models.products.category"
                        >
                            <AutocompleteArrayInput />
                        </ReferenceArrayInput>

                        <ReferenceInput
                            source="topping_group_id"
                            reference="delivery-takeout/topping-groups"
                            fullWidth
                            label="models.products.topping_group"
                            allowEmpty
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <RichTextInput
                            source="catalog_product_detail"
                            label="models.products.catalog_product_detail"
                        />
                    </FormTab>
                    <FormTab
                        label="view.products.tabs.edit_sale"
                        path="sales"
                        contentClassName={classes.tab}
                    >
                        <AutocompleteInput
                            source="status"
                            choices={PublishStatusChoices}
                            validate={requiredValidate}
                            fullWidth
                            label="models.products.status"
                        />
                        <AutocompleteInput
                            source="sale_type"
                            choices={SaleTypeChoices}
                            validate={requiredValidate}
                            fullWidth
                            label="models.products.sale_type"
                        />
                        <NumberInput
                            source="display_order"
                            validate={requiredValidate}
                            fullWidth
                            label="models.products.display_order"
                        />
                        <TextInput
                            source="precautionary_statement"
                            fullWidth
                            label="models.products.precautionary_statement"
                        />
                        <NumberInput
                            source="sale_limit_by_delivery_date"
                            fullWidth
                            label="models.products.sale_limit_by_delivery_date"
                        />
                        <NumberInput
                            source="minimum_purchase_amount"
                            fullWidth
                            label="models.products.minimum_purchase_amount"
                        />
                    </FormTab>
                </TabbedForm>
            </Edit>
        </>
    );
};

const requiredValidate = [required()];
const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}
export default ResourceEdit;
