import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    CreateButton,
    ListProps,
    TopToolbar,
    TextField,
    NumberField,
    SearchInput,
    useTranslate,
    useResourceContext, ReferenceField, SelectField,
} from 'react-admin';
import { ReactElement } from 'react';
import {PublishStatusChoices, WorkChoices} from "../../consts";

const ResourceListTitle = () => {
    const translate = useTranslate();
    return <span>{translate(`common.component.submenu.brand.members`)}</span>;
}
const ResourceListActions = ( ) => (
    <TopToolbar>
        <CreateButton basePath={"members"} />
    </TopToolbar>
);
const ResourceListFilters = [
    <SearchInput source="name" placeholder="メンバー名検索" alwaysOn />,
];
const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const ResourceList = (props: ListProps): ReactElement => {
    return (
        <List
            title={<ResourceListTitle />}
            {...props}
            filters={ResourceListFilters}
            perPage={25}
            bulkActionButtons={false}
            actions={<ResourceListActions/>}
        >
            <>
                <ResourceName />
                <Datagrid optimized rowClick="edit" >
                    <TextField
                        source="name"
                        label="models.members.name"
                    />
                    <TextField
                        source="department"
                        label="models.members.department"
                    />
                    <TextField
                        source="login_id"
                        label="models.members.login_id"
                    />
                    <SelectField
                        source="work"
                        choices={WorkChoices}
                        label="models.members.work"
                    />
                    <ReferenceField
                        source="brand"
                        reference="brands"
                        label="models.members.brand"
                        link={false}
                    >
                        <TextField source="identifier" />
                    </ReferenceField>
                </Datagrid>
            </>
        </List>
    );
};

export default ResourceList;
