import { MenuItemLink, MenuProps, ReduxState, useTranslate} from "react-admin";
import {useState} from "react";

import SubMenu from "../SubMenu";
import KitchenIcon from '@material-ui/icons/Kitchen';
import toppingGroups from "../../resources/delivery-takeout/topping-groups";
import toppings from "../../resources/delivery-takeout/toppings";
import products from "../../resources/delivery-takeout/products";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";

type MenuName = 'index';

export default ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        index: true,
    });
    const translate = useTranslate();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <SubMenu
            handleToggle={() => handleToggle('index')}
            isOpen={state.index}
            name="common.component.menu.delivery_takeout"
            icon={<KitchenIcon />}
            dense={dense}
        >
            <MenuItemLink
                to={{
                    pathname: '/delivery-takeout/products',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.delivery_takeout.products`, {
                    smart_count: 2,
                })}
                leftIcon={<products.icon />}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: '/delivery-takeout/topping-groups',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.delivery_takeout.topping_groups`, {
                    smart_count: 2,
                })}
                leftIcon={<toppingGroups.icon />}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: '/delivery-takeout/toppings',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.delivery_takeout.toppings`, {
                    smart_count: 2,
                })}
                leftIcon={<toppings.icon />}
                dense={dense}
            />
        </SubMenu>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));