import { MenuItemLink, MenuProps, ReduxState, useTranslate} from "react-admin";
import {useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../types";
import SubMenu from "../SubMenu";
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import orders from "../../resources/delivery/orders";
import schedules from "../../resources/delivery/schedules";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";

type MenuName = 'index';

export default ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        index: true,
    });
    const translate = useTranslate();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <SubMenu
            handleToggle={() => handleToggle('index')}
            isOpen={state.index}
            name="common.component.menu.delivery"
            icon={<DirectionsBike />}
            dense={dense}
        >
            <MenuItemLink
                to={{
                    pathname: '/delivery/schedules',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.delivery.schedules`, {
                    smart_count: 2,
                })}
                leftIcon={<schedules.icon />}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: '/delivery/orders',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`common.component.submenu.delivery.orders`, {
                    smart_count: 2,
                })}
                leftIcon={<orders.icon />}
                dense={dense}
            />
        </SubMenu>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));