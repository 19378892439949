import React, { useState, useCallback, useEffect} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import RevenueSummary from './RevenueSummary';
import ScheduleSummary from './ScheduleSummary';
import OrderChart from './OrderChart';

import {DeliveryMethod} from "../consts";
import {calculateTodayOrders} from "../utils";

interface State {
    deliveryMewOrders?: number;
    takeoutMewOrders?: number;
    deliverySchedules?: {[index: string]: number};
    takeoutSchedules?: {[index: string]: number};
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Dashboard = () => {
    const [state, setState] = useState<State>({
        deliveryMewOrders: 0,
        takeoutMewOrders: 0,
        deliverySchedules: {},
        takeoutSchedules: {},
    });
    const version = useVersion();
    const dataProvider = useDataProvider();

    const s = useCallback(() => {
            calculateTodayOrders(DeliveryMethod.DELIVERY).then(data => {
                setState(state => ({
                    ...state,
                    deliverySchedules: data,
                }));
            });
            calculateTodayOrders(DeliveryMethod.TAKEOUT).then(data => {
                setState(state => ({
                    ...state,
                    takeoutSchedules: data,
                }));
            })
        }, [dataProvider]);
    useEffect(() => {
        s();
    }, [version]);

    const {
        deliveryMewOrders,
        takeoutMewOrders,
        deliverySchedules,
        takeoutSchedules,
    } = state;
    return (
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <div style={styles.flex}>
                    <ScheduleSummary
                        deliveryMewOrders={deliveryMewOrders}
                        takeoutMewOrders={takeoutMewOrders}
                        deliverySchedules={deliveryMewOrders}
                        takeoutSchedules={deliveryMewOrders}
                    />
                </div>
            </div>
        </div>
    )
};

export default Dashboard;
