import * as React from 'react';
import { Admin, Resource, useTranslate } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import jaMessages from './i18n/ja';

import deliveryOrders from './resources/delivery/orders';
import deliverySchedules from './resources/delivery/schedules';
import takeoutOrders from './resources/takeout/orders';
import takeoutSchedules from './resources/takeout/schedules';
import deliveryTakeoutProducts from './resources/delivery-takeout/products';
import deliveryTakeoutToppingGroups from './resources/delivery-takeout/topping-groups';
import deliveryTakeoutToppings from './resources/delivery-takeout/toppings';
import members from './resources/members';
import dataProviderFactory from './dataProvider';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on Japanese
    return jaMessages;
}, 'ja');

const App = () => {
    const translate = useTranslate();
    return (
        <Admin
            title={translate('common.component.dashboard.title')}
            dataProvider={dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            )}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource name="delivery/schedules" {...deliverySchedules} />
            <Resource name="delivery/orders" {...deliveryOrders} />


            <Resource name="takeout/schedules" {...takeoutSchedules} />
            <Resource name="takeout/orders" {...takeoutOrders} />

            <Resource name="delivery-takeout/products" {...deliveryTakeoutProducts} />
            <Resource name="delivery-takeout/topping-groups" {...deliveryTakeoutToppingGroups} />
            <Resource name="delivery-takeout/toppings" {...deliveryTakeoutToppings} />

            <Resource name="delivery-takeout/categories" />
            <Resource name="delivery-takeout/receipt" />
            <Resource name="delivery-takeout/invoice" />
            <Resource name="delivery-takeout/schedules/total-count" />
            <Resource name="brands"/>
            <Resource name="members" {...members} />

        </Admin>
    );
};

export default App;
