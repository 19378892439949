import * as React from 'react';
import { SVGProps } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: 'auto',
        height: '50px',
    },
});
const Logo = (props: SVGProps<SVGSVGElement>) => {
    const classes = useStyles();

    return (
        <img src="/assets/images/logo.png" alt="" className={classes.img} />
    );
};

export default Logo;
