import * as React from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    FieldProps, useResourceContext, TextField, ReferenceField, ReferenceInput, SelectInput,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import { ToppingGroup } from '../../types';
import {makeStyles} from "@material-ui/core/styles";
export const styles = {
    name: {},
    brand: {},
    hiddenInput: { display: 'none' },
};

const useStyles = makeStyles(styles);
const ResourceEdit = (props: EditProps) => {
    const transform = (data) => {
        if (data.id) {
            delete data.id;
        }
        return data;
    };

    const classes = useStyles(props);
    return (
        <>
            <ResourceName />
            <Edit
                title={<ResourceTitle />}
                component="div"
                undoable={false}
                {...props}
                transform={transform}
            >
                <FormWithRedirect
                    {...props}
                    render={(formProps: any) => (
                        <Card>
                            <form>
                                <CardContent>
                                    <Box display={{ md: 'block', lg: 'flex' }}>
                                        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                            {(() => {
                                                const authority = localStorage.getItem('authority');
                                                if (authority && ["0","12"].includes(authority)) {
                                                    return (<ReferenceInput
                                                        reference="brands"
                                                        source="brand_id"
                                                        fullWidth
                                                        validate={required()}
                                                        label="models.products.brand"
                                                    >
                                                        <SelectInput optionText="name"/>
                                                    </ReferenceInput>);
                                                } else {
                                                    return (<TextInput
                                                        source="brand_id"
                                                        defaultValue={localStorage.getItem('brand')}
                                                        className={classes.hiddenInput}
                                                    >
                                                    </TextInput>);
                                                }
                                            })()}
                                            <TextInput
                                                source="name"
                                                validate={requiredValidate}
                                                fullWidth
                                                label="models.topping_groups.name"
                                            />
                                        </Box>
                                    </Box>
                                </CardContent>
                                <Toolbar
                                    record={formProps.record}
                                    basePath={formProps.basePath}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    saving={formProps.saving}
                                />
                            </form>
                        </Card>
                    )}
                />
            </Edit>
        </>
    );
};

const ResourceName = () => {
    // @ts-ignore
    const {resource} = useResourceContext();
    return <>{resource}</>;
}

const ResourceTitle = ({ record }: FieldProps<ToppingGroup>) => {
    const translate = useTranslate();
    return record ? <span>{translate(`common.component.submenu.delivery_takeout.topping_groups`)} #{record.name}</span> : null;
}

const requiredValidate = [required()];

export default ResourceEdit;
