import * as React from 'react';
import {
    AutocompleteInput,
    Edit,
    EditProps,
    useTranslate,
    required,
    FieldProps,
    TextField,
    Datagrid,
    SimpleShowLayout,
    SelectField,
    FormTab,
    ImageField,
    TabbedForm,
    NumberField,
    ReferenceField,
    ArrayField,
    FunctionField,
    useResourceContext,
    useResourceDefinition,
    DateField,
    Toolbar, SaveButton, DeleteButton, Button,
    TextInput, useEditController, Identifier, BooleanInput, useRecordContext,
} from 'react-admin';

import {Order, Product} from '../../types';
import {
    DeliveryMethod,
    OrderStatusChoices,
    OrderStatusIds,
    SaleTypeChoices,
    PaymentMethodIds,
    PaymentMethodChoices
} from '../../consts';
import {makeStyles} from "@material-ui/core/styles";
import {styles as createStyles} from "../products/ResourceCreate";
import {useState} from "react";
import CreateDocModalButton from "./CreateDocModalButton";

const ResourceTitle = (props) => {
    const translate = useTranslate();
    return props.record ? <span>
        {translate(props.delivery_method_caption)}{translate(`common.component.submenu.delivery.orders`)} #{props.record.id}</span> : null;
}
const useStyles = makeStyles({
    ...createStyles,
    tab: {
        display: 'block',
    },
    table: {
        width: '100%'
    },
    headerCell: {
        backgroundColor: '#eeeeee',
    },
    image: {
        width: '125px',
        height: 'auto',
    },
});

const ResourceEdit = (props: EditProps) => {
    // @ts-ignore
    const {resource} = useResourceContext();
    const {options} = useResourceDefinition({resource: resource})

    const classes = useStyles();

    const transform = (data) => {
        // putするデータをorder_statusと領収書発行時の領収署名のみとする
        data = (({ order_status, receipt_name, ryosyu_tadasi, invoice_name, invoice_message }) => ({ order_status, receipt_name, ryosyu_tadasi, invoice_name, invoice_message }))(data);
        return data;
    };

    const translate = useTranslate();

    const {record} = useEditController<Order>(props);
    const [orderStatus, setOrderStatus] = useState<Identifier>(record? record.order_status : 1);
    const isReceipt = record? record.receipt: false;
    const isInvoicePayment = record? record.payment_method === PaymentMethodIds.INVOICE_PAYMENT_ID : false;
    if (!record || record === undefined) {
        return null;
    }
console.log(record);

    return (
        <>
            <>{resource}</>
            <Edit
                title={<ResourceTitle delivery_method_caption={options.delivery_method_caption}/>}
                component="div"
                undoable={false}
                {...props}
                transform={transform}

            >
                <TabbedForm>
                    <FormTab
                        label="view.orders.tabs.edit_order"
                        contentClassName={classes.tab}
                    >
                        {(() => {
                            const authority = localStorage.getItem('authority');
                            if (authority && ["0","12"].includes(authority)) {
                                return (
                                    <ReferenceField
                                        source="brand_id"
                                        reference="brands"
                                        link={false}
                                        label="models.products.brand"
                                    >
                                        <TextField source="name"/>
                                    </ReferenceField>);
                            }
                        })()}
                        <AutocompleteInput
                            source="order_status"
                            choices={OrderStatusChoices}
                            validate={requiredValidate}
                            fullWidth
                            label="models.orders.order_status"
                            onChange={event => setOrderStatus(event)}
                        />
                        <h4>{translate("models.orders.product_info")}</h4>
                        <NumberField
                            source="total"
                            label="models.orders.total"
                            locales="ja-JA"
                            options={{
                                style: 'currency',
                                currency: 'JPY',
                            }}
                        />
                        <ArrayField
                            source="order_items"
                            label="models.orders.order_item"
                        >
                            <Datagrid
                                classes={{table: classes.table}}
                            >
                                <TextField source="product_name" label="models.order_items.product_name"/>
                                <ReferenceField
                                    source="product_id"
                                    reference="delivery-takeout/products"
                                    label="models.order_items.product_image"
                                >
                                    <ImageField
                                        source="images[0].file_name"
                                        classes={{image: classes.image}}
                                    />
                                </ReferenceField>
                                <NumberField
                                    source="product_price"
                                    locales="ja-JA"
                                    options={{
                                        style: 'currency',
                                        currency: 'JPY',
                                        minimumFractionDigits: 0
                                    }}
                                />
                                <FunctionField
                                    label="models.order_items.order_count"
                                    render={record => `${record!.order_count} 個`}
                                />
                                <ArrayField
                                    source="order_toppings"
                                    label="models.order_items.order_toppings"
                                >
                                    <Datagrid
                                        classes={{headerCell: classes.headerCell}}
                                    >
                                        <ReferenceField
                                            source="topping_id"
                                            reference="delivery-takeout/toppings"
                                            link={true}
                                            label="models.order_toppings.topping_name"
                                        >
                                            <TextField source="name"/>
                                        </ReferenceField>
                                        <FunctionField
                                            label="models.order_toppings.topping_count"
                                            render={record => `${record!.topping_count} 個`}
                                        />
                                    </Datagrid>
                                </ArrayField>
                            </Datagrid>
                        </ArrayField>


                        { options.delivery_method === DeliveryMethod.DELIVERY && (
                            <SimpleShowLayout>
                                <h4>{translate("models.orders.delivery_info")}</h4>
                                <FunctionField
                                    label="models.orders.delivery_address"
                                    render={record => `〒${record!.delivery_postcode} ${record!.delivery_address}${record!.delivery_building}`}
                                />;
                                <FunctionField
                                    label="models.orders.order_datetime"
                                    render={record => `${record!.order_date} ${record!.order_time}`}
                                />;
                            </SimpleShowLayout>
                        )}
                        <SimpleShowLayout>

                            <h4>{translate("models.orders.customer_info")}</h4>
                            <TextField
                                source="customer_name"
                                label="models.orders.customer_name"
                            />
                            <TextField
                                source="customer_name_kana"
                                label="models.orders.customer_name_kana"
                            />
                            <TextField
                                source="customer_email"
                                label="models.orders.customer_email"
                            />
                            <TextField
                                source="customer_tel"
                                label="models.orders.customer_tel"
                            />

                            <FunctionField
                                label="models.orders.customer_address"
                                render={record => `〒${record!.customer_postcode} ${record!.customer_address}${record!.customer_building}`}
                            />;

                            <SelectField
                                source="payment_method"
                                choices={PaymentMethodChoices}
                                label="models.orders.payment_method"
                            />
                            <FunctionField
                                label="models.orders.receipt"
                                render={record => `${record!.receipt ? "必要" : "不要"}`}
                            />;
                        </SimpleShowLayout>
                        <CreateDocModalButton
                            onChange={()=>1}
                            kind="RECEIPT"
                            orderId={record.id}
                            message={record.message}
                            memo={record.memo}
                        />
                        { isInvoicePayment && (
                            <CreateDocModalButton
                                onChange={()=>1}
                                kind="INVOICE"
                                orderId={record.id}
                                message={record.message}
                                memo={record.memo}
                            />
                        )}
                    </FormTab>
                </TabbedForm>
            </Edit>
        </>
    );
};

const requiredValidate = [required()];
export default ResourceEdit;
